/**
 * @description generate a key (p256dh or auth) from subscription response
 * @param keyName type of key to generate
 * @param subscription object received when a subscription was success
 */
const generateKey = (keyName, subscription) => {
  const rawKey = subscription.getKey ? subscription.getKey(keyName) : ''
  return rawKey ? btoa(String.fromCharCode.apply(null, new Uint8Array(rawKey))) : ''
}

/**
 * @description get a p256dh key from subscription response
 * @param subscription object received when a subscription was success
 */
const generatePublicKey = (subscription) => generateKey('p256dh', subscription)

/**
 * @description get a auth key from subscription response
 * @param subscription object received when a subscription was success
 */
const generateAuthKey = (subscription) => generateKey('auth', subscription)

/**
 * @description generate a valid applicationServerKey from the vapidkey
 * @param base64String vapidkey received from backend
 */
const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export {
  generatePublicKey,
  generateAuthKey,
  urlBase64ToUint8Array
}
